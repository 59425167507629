<template>
    <div>
        <s-form @submit="dataEmit">
            <div class="pl-0">
                <div class="row mb-4">
                    <div class="col-lg-2">
                        <validated-date-picker :rules="{required:true}" format="DD-MM-YYYY"
                                               class="c-input-select field-required"
                                               label="Date of Clubbing" v-model="model.clubbing_date"></validated-date-picker>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-lg-3 ">
                        <p class="text-primary mt-2">Selection 1</p>
                        <p class="text-primary pt-4">Selection 2</p>
                        <p class="text-primary pt-4">Selection 3</p>
                        <p class="text-primary pt-4">Selection 4</p>
                        <p class="text-primary font-poppins-semibold pt-4">TOTAL</p>
                        <p class="text-primary pt-4">Position Dose in Long Storage</p>
                    </div>
                    <div class="col-lg-2 mr-4">
                        <validated-input disabled v-model="selection1.pos1"></validated-input>
                        <validated-input disabled v-model="selection2.pos1"></validated-input>
                        <validated-input disabled v-model="selection3.pos1"></validated-input>
                        <validated-input disabled v-model="selection4.pos1"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-vue-select @input="FSDStorageOptions" :rules="{required:true}"
                                              :options="storageTypeOptions"
                                              label="Storage Type" v-model="model.storage_type">

                        </validated-vue-select>

                    </div>
                    <div class="col-lg-2 mr-4">
                        <validated-input disabled v-model="selection1.pos2"></validated-input>
                        <validated-input disabled v-model="selection2.pos2"></validated-input>
                        <validated-input disabled v-model="selection3.pos2"></validated-input>
                        <validated-input disabled v-model="selection4.pos2"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-vue-select :rules="{required:true}"
                                              :options="FSDStorageNameOptions" label="Storage Name"
                                              v-model="model.storage_name">

                        </validated-vue-select>

                    </div>
                    <div class="col-lg-2">
                        <validated-input disabled v-model="selection1.pos3"></validated-input>
                        <validated-input disabled v-model="selection2.pos3"></validated-input>
                        <validated-input disabled v-model="selection3.pos3"></validated-input>
                        <validated-input disabled v-model="selection4.pos3"></validated-input>
                        <validated-input disabled v-model="total"></validated-input>
                        <validated-input :rules="{required:true}"
                                         label="Location" v-model="FSDLocation">

                        </validated-input>
                    </div>
                    <div class="col-lg-2">
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-input style="visibility: hidden"></validated-input>
                        <validated-ajax-vue-select :rules="{required:true}"
                                                   :url="positionOptions" label="Goblet Type" v-model="model.storage_position">

                        </validated-ajax-vue-select>

                    </div>
                    <!--          <div class="col-12">-->
                    <!--            <div class="btn-group">-->
                    <!--              <btn text="Clear" type="reset" @click="clearData"></btn>-->
                    <!--              <btn text="view Storage Position" type="reset"></btn>-->
                    <!--            </div>-->
                    <!--          </div>-->

                </div>

                <div class="fl-te-c  mt-6">
                    <div class="btn-group">
                        <btn text="Clear" type="reset" @click="clearData"></btn>
                        <btn text="View Storage Position" type="reset" @click="viewStorage"></btn>
                    </div>
                    <div class="btn-group">
                        <btn class="mr-3 px-4" text="Save" :loading="loading" loading-text="Saving..."></btn>
                        <btn design="basic-b" type="reset" @click="BackToHome">Cancel</btn>
                    </div>
                </div>
            </div>
        </s-form>
         <modal title="View Storage Position" ref="viewStoragePosition" :no-close-on-backdrop="true" width="70vw" header-color="primary">
                <SemenStorageDiagramPage></SemenStorageDiagramPage>
            </modal>
    </div>
</template>

<script>

import urls from '../../data/urls';
import axios from 'secure-axios';
import masterURLs from '../../data/masterURLs';
import SemenStorageDiagramPage from '../../views/bulls/semen-storage-diagram/SemenStorageDiagramPage';

export default {
    name: 'ClubbingOfSemenDoseInputSection',
    components: { SemenStorageDiagramPage },
    props: {
        details: {
            type: Array,
            required: false
        },
        semenData: {
            type: Array,
            required: false
        }
    },
    data () {
        return {
            URL: urls.clubbingofSemenDose.addEdit,
            loading: false,
            model: {
                clubbing_date: '',
                // position_dose: '',
                storage_type: '',
                storage_name: '',
                storage_location: '',
                storage_position: ''
            },
            selection1: {
                pos1: '',
                pos2: '',
                pos3: ''
            },
            selection2: {
                pos1: '',
                pos2: '',
                pos3: ''
            },
            selection3: {
                pos1: '',
                pos2: '',
                pos3: ''
            },
            selection4: {
                pos1: '',
                pos2: '',
                pos3: ''
            },
            storageTypeOptions: [
                { label: 'JUMBO', value: 'JUMBO' },
                { label: 'OMR', value: 'OMR' }
            ],
            FSDStorageNameOptions: [],
            storageNameOptionsURL: masterURLs.master.storageType.vueSelect,
            locationOptions: masterURLs.master.quarantineLocation.vueSelect,
            positionOptions: masterURLs.master.quarantinePosition.vueSelect

        };
    },
    computed: {
        FSDLocation: {
            get () {
                return this.model.storage_location.toUpperCase();
            },
            set (value) {
                this.model.storage_location = value.toUpperCase();
            }
        },
        total () {
            return (
                parseInt(this.selection1.pos3 || 0) +
                parseInt(this.selection2.pos3 || 0) +
                parseInt(this.selection3.pos3 || 0) +
                parseInt(this.selection4.pos3 || 0)
            );
        }
    },
    watch: {
        semenData (newValue, oldValue) {
            if (newValue) {
                console.log('watch:', this.semenData);
                this.itemAssignment();
            }
        }
    },
    methods: {
        viewStorage () {
            this.$refs.viewStoragePosition.show();
        },
        async FSDStorageOptions () {
            const response = await axios.get(this.storageNameOptionsURL + '?storage_type=' + this.model.storage_type);
            this.FSDStorageNameOptions = response.data.data;
        },
        loadingTure () {
            this.loading = true;
        },
        loadingFalse () {
            this.loading = false;
        },
        dataEmit () {
            this.$emit('data', this.model);
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        clearData () {
            this.selection1.pos1 = '';
            this.selection1.pos2 = '';
            this.selection1.pos3 = '';

            this.selection2.pos1 = '';
            this.selection2.pos2 = '';
            this.selection2.pos3 = '';

            this.selection3.pos1 = '';
            this.selection3.pos2 = '';
            this.selection3.pos3 = '';

            this.selection4.pos1 = '';
            this.selection4.pos2 = '';
            this.selection4.pos3 = '';
            this.$emit('clear');
        },
        itemAssignment () {
            const Arr = this.semenData;
            console.log('Arr', Arr);
            if (Arr.length >= 1) {
                console.log('Array Length >1', Arr.length);
                this.selection1.pos1 = Arr[0].bull.reg_no;
                this.selection1.pos2 = Arr[0].ejaculation_no;
                this.selection1.pos3 = Arr[0].fsd_dose;
            }
            if (Arr.length >= 2) {
                console.log('Array Length>2', Arr.length);
                this.selection2.pos1 = Arr[1].bull.reg_no;
                this.selection2.pos2 = Arr[1].ejaculation_no;
                this.selection2.pos3 = Arr[1].fsd_dose;
            }
            if (Arr.length >= 3) {
                console.log('Array Length>3', Arr.length);
                this.selection3.pos1 = Arr[2].bull.reg_no;
                this.selection3.pos2 = Arr[2].ejaculation_no;
                this.selection3.pos3 = Arr[2].fsd_dose;
            }
            if (Arr.length >= 4) {
                console.log('Array Length>4', Arr.length);
                this.selection4.pos1 = Arr[3].bull.reg_no;
                this.selection4.pos2 = Arr[3].ejaculation_no;
                this.selection4.pos3 = Arr[3].fsd_dose;
            }
        }

    }
};
</script>

<style scoped>

</style>
